import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const Enroll = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    gender: '',
    levelOfStudy: '',
    github: '',
    course: '',
    reasonForLearning: '',
    availableTimes: '',
    expectedStartDate: '',
    expectations: '',
    mpesaTransactionId: ''
  });

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:5000/enroll', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        toast.success('Enrollment successful!');
        setFormData({
          name: '',
          email: '',
          phone: '',
          gender: '',
          levelOfStudy: '',
          github: '',
          course: '',
          reasonForLearning: '',
          availableTimes: '',
          expectedStartDate: '',
          expectations: '',
          mpesaTransactionId: ''
        });
        setStep(1); // Reset to the first step
        
        // Redirect to WhatsApp group
        window.location.href = 'https://chat.whatsapp.com/LVnkxnqMw3nIBfWtZyhebS';
      } else {
        const errorMessage = await response.text();
        toast.error(`Enrollment failed: ${errorMessage}`);
      }
    } catch (error) {
      toast.error('An unexpected error occurred.');
    }
  };



    // code for metadata
useEffect(() => {
  document.title = "Sakazidevs Academy - Enroll now for our comphrehensive web development course";

  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', 'description');
  metaDescription.setAttribute('content', 'Enroll in few steps to get started with our flexible, comphrehensive course');
  document.head.appendChild(metaDescription);


  const metaKeywords = document.createElement('meta');
  metaKeywords.setAttribute('name', 'keywords');
  metaKeywords.setAttribute('content', 'contact us, learn web, enroll, learn frontend, learn tech, learn backend, learn full-stack development, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
  document.head.appendChild(metaKeywords);


  return () => {
    document.title = "";

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  };
}, []);



  return (
    <>
      <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 sm:py-24">
        <div className="bg-white p-6 sm:p-8 rounded shadow-lg w-full max-w-lg">
          <h2 className="text-xl sm:text-2xl text-navyblue font-bold mb-6 sm:mb-8 text-center">
            Enroll in Web Development Course <br />
            <span className='text-sm italic text-orange'>NB: You have to make payment first before filling this form</span>
          </h2>
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <div>
                {/* Step 1 Fields */}
                <div className="mb-4">
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="gender" className="block text-sm font-medium text-gray-700">Gender</label>
                  <select
                    name="gender"
                    id="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  >
                    <option value="">Select gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="levelOfStudy" className="block text-sm font-medium text-gray-700">Level of Study</label>
                  <select
                    name="levelOfStudy"
                    id="levelOfStudy"
                    value={formData.levelOfStudy}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  >
                    <option value="">Select level of study</option>
                    <option value="highschool">High School</option>
                    <option value="undergraduate">Undergraduate</option>
                    <option value="postgraduate">Postgraduate</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="github" className="block text-sm font-medium text-gray-700">GitHub Link (Optional)</label>
                  <input
                    type="url"
                    name="github"
                    id="github"
                    value={formData.github}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={handleNext}
                    className="bg-orange text-white py-2 px-4 rounded hover:bg-orange-dark"
                  >
                    Next
                  </button>
                </div>
              </div>
            )}
            {step === 2 && (
              <div>
                {/* Step 2 Fields */}
                <div className="mb-4">
                  <label htmlFor="course" className="block text-sm font-medium text-gray-700">Module</label>
                  <select
                    name="course"
                    id="course"
                    value={formData.course}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  >
                    <option value="">Select a module</option>
                    <option value="frontend">Front-end Development - 12,000 KSH</option>
                    <option value="backend">Back-end Development - 15,000 KSH</option>
                    <option value="fullstack">Full-stack Development - 30,000 KSH</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="reasonForLearning" className="block text-sm font-medium text-gray-700">Why did you choose to learn web development?</label>
                  <textarea
                    name="reasonForLearning"
                    id="reasonForLearning"
                    value={formData.reasonForLearning}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="availableTimes" className="block text-sm font-medium text-gray-700">Times you're available during the day</label>
                  <select
                    name="availableTimes"
                    id="availableTimes"
                    value={formData.availableTimes}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  >
                    <option value="">Select available times</option>
                    <option value="morning">Morning (8am - 12pm)</option>
                    <option value="afternoon">Afternoon (12pm - 4pm)</option>
                    <option value="evening">Evening (4pm - 8pm)</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="expectedStartDate" className="block text-sm font-medium text-gray-700">Your expected start date</label>
                  <input
                    type="date"
                    name="expectedStartDate"
                    id="expectedStartDate"
                    value={formData.expectedStartDate}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="expectations" className="block text-sm font-medium text-gray-700">What do you expect to gain from this course?</label>
                  <textarea
                    name="expectations"
                    id="expectations"
                    value={formData.expectations}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  ></textarea>
                </div>
                <div className="mb-4">
                  <label htmlFor="mpesaTransactionId" className="block text-sm font-medium text-gray-700">MPESA Transaction ID (For verification)</label>
                  <input
                    type="text"
                    name="mpesaTransactionId"
                    id="mpesaTransactionId"
                    value={formData.mpesaTransactionId}
                    onChange={handleChange}
                    className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange focus:border-orange"
                    required
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={handleBack}
                    className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="bg-orange text-white py-2 px-4 rounded hover:bg-orange-dark"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Enroll;
