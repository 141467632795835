import './App.css';
import { Route, Routes} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGithub, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import React, { useState } from 'react';
// main pages
import Home from './Home';
import Enroll from './Enroll';

//images
import Logo from './media/mylogo.png';





function App() {
  
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (

<>
<header>
<nav className='flex justify-between items-center w-full fixed z-10 p-2'>
        <img src={Logo} className='w-16 height-16' alt='Sakazidevs Acdemy'/>
        <div className="relative">
      <button
        onClick={toggleMenu}
        className="fixed top-4 right-4 z-50 p-2 bg-navyblue text-white rounded h-8 w-8 flex items-center justify-center focus:outline-none"
      >
        {isOpen ? '✕' : '☰'}
      </button>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out"
          onClick={closeMenu}
        ></div>
      )}

      <div
        className={`fixed top-0 right-0 h-full z-50 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        } w-full md:w-1/3`}
      >
        <button
          onClick={closeMenu}
          className="absolute top-4 right-4 text-gray-800 focus:outline-none"
        >
          ✕
        </button>
        <nav className="mt-16 p-4">
          <ul className="space-y-4">
            <li>
              <a href="#home" className="text-gray-800 text-lg font-semibold">
                Home
              </a>
            </li>
            <li>
              <a href="#about" className="text-gray-800 text-lg font-semibold">
                Contact
              </a>
            </li>
            <li>
              <a href="#contact" className="text-gray-800 text-lg font-semibold">
                Enroll
              </a>
            </li>
            <li>
              <a href="#contact" className="text-gray-800 text-lg font-semibold">
                Email: info@sakazidevs.com
              </a>
            </li>
            <li>
              <a href="#contact" className="text-gray-800 text-lg font-semibold">
                Call: +254746092415
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
      </nav>
</header>
<Routes>
    <Route path="/" element={<Home />} />
    <Route path="/enroll" element={<Enroll />} />
</Routes>


<footer className='bg-navyblue p-4'>
  <div className='container mx-auto flex flex-col md:flex-row justify-between items-center'>
    {/* Social Media Links */}
    <div className="flex space-x-4 mb-4 md:mb-0">
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} className="text-white text-2xl" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitter} className="text-white text-2xl" />
      </a>
      <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className="text-white text-2xl" />
      </a>
      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} className="text-white text-2xl" />
      </a>
    </div>
    
    {/* Contact Information */}
    <div className='mb-4 md:mb-0'>
      <a href='https://wa.me/254746092415' className='px-3 font-bold text-white'>+254746092415</a>
      <a href='https://sakazidevs.com/contact' className='px-3 font-bold text-white'>Contact</a>
    </div>
    
    {/* Powered By */}
    <p className='text-white text-center md:text-right'>
      Powered by <a href='https://sakazidevs.com' className='text-orange'>Sakazidevs</a>
    </p>
  </div>
</footer>

</>
  );
}
export default App;

