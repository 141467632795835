import React, { useState, useEffect } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPlayCircle, faClock, faCertificate, faProjectDiagram, faUsers } from '@fortawesome/free-solid-svg-icons';
import HeroBg from './media/hero-bg.png';
import Instructor from './media/Phillip-Mutuku.png';



const FAQItem = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleFAQ = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="border-b border-gray-200">
      <button
        onClick={toggleFAQ}
        className="w-full flex justify-between items-center py-4 text-left focus:outline-none"
      >
        <span className="text-lg font-medium">{question}</span>
        <span className="text-2xl">{isExpanded ? '-' : '+'}</span>
      </button>
      {isExpanded && <div className="py-2 text-gray-700">{answer}</div>}
    </div>
  );
};



const Home = () => {


  const faqData = [
    {
      question: 'What is web development?',
      answer: 'Web development involves the creation and maintenance of websites. It includes web design, web content development, client-side/server-side scripting, and network security configuration, among other tasks.'
    },
    {
      question: 'Why should I learn web development?',
      answer: 'Learning web development opens up numerous opportunities in the tech industry. It equips you with the skills to create websites and web applications, which are in high demand. Additionally, it can enhance your problem-solving skills and creativity.'
    },
    {
      question: 'What do your web development courses cover?',
      answer: 'Our courses cover front-end development (HTML, CSS, JavaScript), back-end development (Node.js, databases), and full-stack development. We provide hands-on projects and real-world scenarios to ensure practical learning.'
    },
    {
      question: 'How long does it take to complete a web development course?',
      answer: 'The duration of our web development courses varies. Our front-end and back-end courses typically take around 3-4 months each to complete, while the full-stack course may take around 6-8 months, depending on your learning pace.'
    },
    {
      question: 'What are the prerequisites for enrolling in your web development courses?',
      answer: 'No prior programming experience is required to enroll in our beginner courses. However, for advanced courses, basic knowledge of HTML, CSS, and JavaScript is recommended.'
    },
    {
      question: 'Can I get a job after completing the web development course?',
      answer: 'Yes, our courses are designed to equip you with the necessary skills to pursue a career in web development. We also offer job placement assistance and resume building workshops to help you get started in the industry.'
    },
    {
      question: 'What is the difference between front-end and back-end development?',
      answer: 'Front-end development focuses on the visual and interactive aspects of a website, using technologies like HTML, CSS, and JavaScript. Back-end development involves server-side logic, databases, and application integration, using languages like Node.js, Python, or PHP.'
    },
    {
      question: 'What tools and technologies are used in web development?',
      answer: 'Web development utilizes various tools and technologies, including HTML, CSS, JavaScript, frameworks like React and Angular, server-side languages like Node.js and Python, databases like MySQL and MongoDB, and version control systems like Git.'
    },
    {
      question: 'What is responsive web design?',
      answer: 'Responsive web design ensures that a website looks and functions well on different devices and screen sizes. It involves using flexible layouts, images, and CSS media queries to create a seamless user experience across desktops, tablets, and smartphones.'
    },
    {
      question: 'How can I deploy my web application?',
      answer: 'Deploying a web application involves transferring it from your local development environment to a live server. This can be done using various hosting services like AWS, Heroku, or Netlify. The deployment process may include setting up a domain name, configuring server settings, and managing databases.'
    },
    {
      question: 'What are the best practices for web development?',
      answer: 'Best practices for web development include writing clean, maintainable code, using version control, optimizing performance, ensuring accessibility, following security guidelines, and continuously testing and debugging your application.'
    },
    {
      question: 'How do I keep up with the latest trends in web development?',
      answer: 'To stay updated with the latest trends in web development, you can follow industry blogs, participate in online communities, attend conferences and workshops, and continuously learn new technologies and frameworks.'
    }
  ];



  const [paymentOption, setPaymentOption] = useState('full'); // 'full' or 'installment'
  const [isPaid, setIsPaid] = useState(false);

  const totalPrice = 1000; // example total price
  const discountPrice = totalPrice * 0.95; // 5% discount for full payment

  const handlePaymentOptionChange = (option) => {
    setPaymentOption(option);
  };

  const handlePayment = () => {
    setIsPaid(true);
  };


  // code for metadata
useEffect(() => {
  document.title = "Sakazidevs Academy - Master web development in 4 months";

  const metaDescription = document.createElement('meta');
  metaDescription.setAttribute('name', 'description');
  metaDescription.setAttribute('content', 'Unlock your potential with our comprehensive web development courses! Learn full-stack development, and more with hands-on projects, expert instructors, and a supportive community. Start your journey to becoming a professional web developer today.');
  document.head.appendChild(metaDescription);


  const metaKeywords = document.createElement('meta');
  metaKeywords.setAttribute('name', 'keywords');
  metaKeywords.setAttribute('content', 'contact us,learn web, learn frontend, learn tech, learn backend, learn full-stack development, request quote, sakazi devs, developers kenya, best ech services in Kenya, web design, seo, IT services, Ai Integration, Graphic Design, automation, content management systems, wordpress, shopify, website design, web applications, ui and ux design, graphics, design, IT firm, best web design services, consultancy, IT consultancy, best graphic designers, logo designers, logo design, poster design, business card design, portolio design, corporate website, static website, dynamic websites, ai prompting, ai trends, ai in business, automated services, digital marketing, email marketing, copywriting, digital services');
  document.head.appendChild(metaKeywords);


  return () => {
    document.title = "";

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  };
}, []);


  return (
    <div className='bg-slate-100'>
<header className='h-screen w-full pt-10'>
  <div className='flex flex-col lg:flex-row justify-around items-center pt-4 px-3 '>
    <div className='text-center mt-20 lg:text-left'>
      <h1 className='text-4xl lg:text-8xl font-extrabold text-navyblue'>
        Master Web Development <br />
        <span className='text-3xl lg:text-6xl text-orange font-semibold'>in 4 months</span>
      </h1>
      <p className='pt-4 text-lg lg:text-xl italic'>
        You will learn everything you need to know in web development
      </p>
    </div>
    <div className='mt-8 lg:mt-0'>
      <img src={HeroBg} className='w-3/4 lg:w-full h-auto p-10 bg-fixed rounded-full mx-auto' alt='Web Development' />
    </div>
  </div>
</header>




<section className='flex flex-col lg:flex-row justify-around p-10 items-center my-32'>
  <img 
    src='https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2023/10/Website-Development.jpg' 
    className='w-72 h-72 lg:w-96 lg:h-96 rounded-full object-cover mb-8 lg:mb-0' 
    alt='What is web development by Sakazidevs Academy'
  />
  <div className='max-w-full lg:pl-24 text-center lg:text-left'>
    <h1 className='text-3xl lg:text-4xl font-bold mb-4 text-navyblue'>What is Web Development?</h1>
    <p className='text-base lg:text-lg'>
      Web development involves all the tasks and processes required to create and maintain websites and web applications that are accessible via the internet or an intranet. It includes key aspects which include; frontend development, backend development, full-stack development, web security, web design, web accessibility, responsive design, web performance optimization, version control, and web standards & best practices.
    </p>
    <br />
    <a href='#' className='text-orange flex justify-center lg:justify-start items-center'>
      <FontAwesomeIcon icon={faPlayCircle} className='mr-2' /> Watch Intro Video
    </a>
  </div>
</section>



<div className="max-w-full lg:max-w-3xl mx-auto p-4 lg:p-6 shadow-custom">
  <h2 className="text-3xl lg:text-4xl text-navyblue font-bold text-center mb-4 lg:mb-6">Frequently Asked Questions (FAQs)</h2>
  <p className="text-center text-gray-600 mb-4 lg:mb-6">
    In this FAQ section, you can find all the information about our web development courses and more
  </p>
  {faqData.map((faq, index) => (
    <FAQItem key={index} question={faq.question} answer={faq.answer} />
  ))}
</div>




<section className='my-16 w-full h-auto' id='instructor'>
  <h1 className='text-center text-navyblue text-3xl lg:text-4xl font-bold mb-8'>Meet the Instructor</h1>
  <div className='flex flex-col lg:flex-row justify-around items-center lg:items-start lg:h-96'>
    <div className='w-full lg:w-1/2 flex justify-center lg:justify-end'>
      <img 
        src={Instructor} 
        className='w-72 h-72 lg:w-[450px] lg:h-[450px] rounded-full mb-8 lg:mb-0' 
        alt='Phillip Mutuku - Web Instructor at Sakazidevs Academy' 
      />
    </div>
    <div className='w-full lg:w-1/2 lg:pl-12 p-4 lg:p-6 text-center lg:text-left'>
      <h2 className='text-2xl lg:text-3xl text-navyblue font-bold mb-4'>Phillip Mutuku - Full-Stack Developer</h2>
      <h5 className='text-xl lg:text-2xl text-orange py-2'>About me</h5>
      <p className='mb-4'>
        I'm Phillip Mutuku, a dedicated full-stack developer and web development instructor. With years of hands-on experience in both front-end and back-end technologies, I specialize in creating efficient, user-friendly web applications. My expertise includes front-end frameworks like React, Angular, and Vue.js, as well as back-end technologies such as Node.js, Django, Flask, and PHP. I also have extensive knowledge of SQL and NoSQL databases, ensuring robust and scalable application development.
      </p>
      <p className='mb-4'>
        As an instructor, my goal is to share my knowledge and passion for web development with aspiring developers. I focus on delivering comprehensive, practical lessons that cover everything from the basics to advanced concepts, helping students build a solid foundation and stay up-to-date with the latest industry trends. My teaching approach emphasizes writing clean, maintainable code and understanding user needs to create exceptional software solutions.
      </p>
      <p>
        Beyond teaching, I enjoy contributing to open-source projects, participating in hackathons, and continuously exploring new technologies to stay at the forefront of the tech landscape. I am excited to guide you on your web development journey and help you turn your ideas into reality!
      </p>
    </div>
  </div>
</section>


<section className='relative py-24'>
  <h1 className='text-3xl lg:text-4xl text-center text-navyblue font-bold mt-24'>Plans & Pricing</h1>
  <div className='flex flex-col lg:flex-row justify-around flex-wrap p-4 lg:p-10 items-center text-white gap-8'>
    
    <div className='w-full lg:w-1/3 bg-navyblue rounded p-6 relative'>
      <span className='absolute -top-4 left-1/2 transform -translate-x-1/2 rounded-full bg-navyblue text-white h-8 w-8 flex justify-center items-center'>1</span>
      <h2 className='font-bold text-2xl mb-4'>Frontend Development</h2>
      <h4 className='font-bold py-2'>You will learn:</h4>
      <ul className='pl-6'>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> HTML + CSS</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Tailwind CSS</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Bootstrap</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> JavaScript</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> React.js</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> CMS Integration</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Deployment</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Web Security</li>
      </ul>
      <div className='flex justify-between items-center mt-6'>
        <h5>Duration: 2 months</h5>
        <span className='bg-orange text-white px-2 py-1 rounded'>12,000 KSH</span>
      </div>
      <p className='pt-6'><u>NB:</u> Mode of learning is <span className='font-bold'>Online, 2 hours per session</span></p>
    </div>

    <div className='w-full lg:w-1/3 bg-navyblue rounded p-6 relative'>
      <span className='absolute -top-4 left-1/2 transform -translate-x-1/2 rounded-full bg-navyblue text-white h-8 w-8 flex justify-center items-center'>2</span>
      <h2 className='font-bold text-2xl mb-4'>Backend Development</h2>
      <h4 className='font-bold py-2'>You will learn:</h4>
      <ul className='pl-6'>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Node.js</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> SQL Basics</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> MySQL & PostgreSQL</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Express.js</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> RESTful API Development</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Authentication & Authorization</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Deployment</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Web Security</li>
      </ul>
      <div className='flex justify-between items-center mt-6'>
        <h5>Duration: 3 months</h5>
        <span className='bg-orange text-white px-2 py-1 rounded'>15,000 KSH</span>
      </div>
      <p className='pt-6'><u>NB:</u> Mode of learning is <span className='font-bold'>Online, 2 hours per session</span></p>
    </div>

    <div className='w-full lg:w-1/3 bg-navyblue rounded p-6 relative'>
      <span className='absolute -top-4 left-1/2 transform -translate-x-1/2 rounded-full bg-navyblue text-white h-8 w-8 flex justify-center items-center'>3</span>
      <h2 className='font-bold text-2xl mb-4'>Full-stack Development</h2>
      <h4 className='font-bold py-2'>You will learn:</h4>
      <ul className='pl-6'>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> HTML + CSS</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Tailwind CSS</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> JavaScript & React.js</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Node.js & Express.js</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> SQL & NoSQL Databases</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> API Development</li>
        <li className='mb-2'><FontAwesomeIcon icon={faCheck} className="text-white" /> Web Security & Deployment</li>
      </ul>
      <div className='flex justify-between items-center mt-6'>
        <h5>Duration: 5 months</h5>
        <span className='bg-orange text-white px-2 py-1 rounded'>30,000 KSH</span>
      </div>
      <p className='pt-6'><u>NB:</u> Mode of learning is <span className='font-bold'>Online, 2 hours per session</span></p>
    </div>

  </div>
</section>





<div className="max-w-3xl mx-auto p-4 sm:p-6">
  <h2 className="text-3xl sm:text-4xl font-bold text-navyblue text-center mb-4 sm:mb-6">Payment Information</h2>
  <p className="text-center text-gray-600 mb-4 sm:mb-6">
    We only accept payments via MPESA till number <strong>784894</strong>
  </p>

  <div className="mb-4 sm:mb-6">
    <h3 className="text-lg font-medium text-center">Payment Options:</h3>
    <p className='text-center mb-4 italic'>There are only two payment options which are:</p>
    <div className="flex justify-center mb-4">
      <button 
        className={`px-4 py-2 mx-1 sm:mx-2 ${paymentOption === 'full' ? 'bg-orange text-white' : 'bg-gray-200 text-gray-700'}`}
        onClick={() => handlePaymentOptionChange('full')}
      >
        Full Payment
      </button>
      <button 
        className={`px-4 py-2 mx-1 sm:mx-2 ${paymentOption === 'installment' ? 'bg-orange text-white' : 'bg-gray-200 text-gray-700'}`}
        onClick={() => handlePaymentOptionChange('installment')}
      >
        Installment
      </button>
    </div>
    
    {paymentOption === 'full' ? (
      <div className="text-center">
        <p className="text-gray-500">You get 7% discount on the total price when you make full payment during course enrollment.</p>
      </div>
    ) : (
      <div className="text-center">
        <p className="text-gray-500">No discount available for installment payments. But we allow someone to make payment in 3 installments before the end of course.</p>
      </div>
    )}
  </div>

  <div className="text-center mb-4 sm:mb-6">
    <button 
      className="bg-green-500 text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md"
      onClick={handlePayment}
    >
      {isPaid ? 'Afterwards Proceed to Enrollment' : 'Make Payment'}
    </button>
  </div>

  {isPaid && (
    <div className="mb-4 sm:mb-6">
      <h3 className="text-lg font-medium mb-2 sm:mb-4">Payment Steps:</h3>
      <ol className="list-decimal list-inside text-sm sm:text-base">
        <li>Open your MPESA menu on your phone.</li>
        <li>Select "Lipa na MPESA".</li>
        <li>Select "Buy Goods and Services".</li>
        <li>Enter the Till Number: <strong>784894</strong>.</li>
        <li>Enter the amount to pay.</li>
        <li>Enter your MPESA PIN and confirm.</li>
        <li>You will receive a confirmation message from MPESA.</li>
      </ol>
      <h2 className='mt-6 sm:mt-12 text-lg sm:text-xl text-navyblue font-semibold text-center'>Proceed to course enrollment now by clicking this button</h2>
      <div className='text-center mt-4'>
        <a href='/enroll' className='bg-orange text-white px-4 py-2 rounded'>Enroll</a>
      </div>
    </div>
  )}
</div>






<div className="flex flex-col md:flex-row items-center justify-between max-w-6xl mx-auto py-16 md:py-32 px-4 sm:px-6 md:px-8">
  <div className="text-left md:w-1/2 mb-8 md:mb-0">
    <h2 className="text-orange font-semibold mb-2 text-lg sm:text-xl">You Deserve a Better Career</h2>
    <h1 className="text-3xl sm:text-4xl font-bold text-navyblue mb-4">Upgrade Your Skills for a Better Future Career</h1>
    <p className="text-gray-600 mb-6 text-base sm:text-lg">
      Learning web development teaches important time management skills, which makes it easier for you to find a good work-study balance.
    </p>
    <button className="bg-orange text-white px-4 sm:px-6 py-2 sm:py-3 rounded-md hover:bg-orange transition duration-300">
      <a href='/enroll'>Enroll Now</a>
    </button>
  </div>
  <div className="md:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
    <div className="p-4 shadow-custom m-2 sm:m-4">
      <FontAwesomeIcon icon={faClock} className="text-orange text-2xl sm:text-3xl mb-4"/><br />
      <div>
        <h3 className="text-lg font-semibold text-gray-900">Flexible Sessions</h3>
        <p className="text-gray-600 text-sm sm:text-base">All sessions are scheduled to times that best fit you.</p>
      </div>
    </div>
    <div className="p-4 shadow-custom m-2 sm:m-4">
      <FontAwesomeIcon icon={faCertificate} className="text-orange text-2xl sm:text-3xl mb-4"/><br />
      <div>
        <h3 className="text-lg font-semibold text-gray-900">Certificate</h3>
        <p className="text-gray-600 text-sm sm:text-base">Receive a certificate upon course completion.</p>
      </div>
    </div>
    <div className="p-4 shadow-custom m-2 sm:m-4">
      <FontAwesomeIcon icon={faProjectDiagram} className="text-orange text-2xl sm:text-3xl mb-4"/><br />
      <div>
        <h3 className="text-lg font-semibold text-gray-900">Real-world Projects</h3>
        <p className="text-gray-600 text-sm sm:text-base">Work on real-world projects to build your portfolio.</p>
      </div>
    </div>
    <div className="p-4 shadow-custom m-2 sm:m-4">
      <FontAwesomeIcon icon={faUsers} className="text-orange text-2xl sm:text-3xl mb-4"/><br />
      <div>
        <h3 className="text-lg font-semibold text-gray-900">Large Community</h3>
        <p className="text-gray-600 text-sm sm:text-base">Connect with other developers upon course completion.</p>
      </div>
    </div>
  </div>
</div>



    </div>
  )
}

export default Home;